var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "footer"
    }
  }, [_c('b-card', {
    staticClass: "bg-transparent",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "py-50 px-1 p-md-1 d-flex-between"
  }, [_vm.isMobileView ? _c('div', [_c('b-button', {
    staticClass: "p-50 py-sm-1 px-sm-2 text-nowrap d-flex-center gap-1",
    attrs: {
      "variant": "warning",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('bv::show::modal', 'modal-result-flightv2-search');
      }
    }
  }, [this.$store.state.app.windowWidth >= 400 ? _c('span', [_vm._v(_vm._s(_vm.$t('flight.flight')))]) : _vm._e(), this.$store.state.app.windowWidth < 400 || this.$store.state.app.windowWidth >= 500 ? _c('b-img', {
    staticClass: "airlines-logo px-0",
    staticStyle: {
      "height": "18px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@icons/airplane-white.svg")
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _c('div', {
    class: "d-flex-center ".concat(!_vm.isMobileView ? 'w-100' : ''),
    staticStyle: {
      "column-gap": "10px"
    }
  }, [_c('b-button', {
    staticClass: "p-50 py-sm-1 px-sm-2 text-nowrap",
    attrs: {
      "variant": "outline-info",
      "pill": "",
      "disabled": _vm.disabledCopyPrice
    },
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('bv::show::modal', 'id-modal-quote-on-booking-search');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.copyPrice')) + " ")]), !_vm.isRoleF3 && _vm.isShowCalcGroupBooking ? _c('b-button', {
    staticClass: "p-50 py-sm-1 px-sm-2 text-nowrap",
    attrs: {
      "variant": "gradient",
      "disabled": !_vm.selectedTrip[_vm.tabIndex],
      "pill": ""
    },
    on: {
      "click": _vm.handleShowBookGroup
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.groupBooking.calculate')) + " ")]) : _vm._e(), _c('b-button', {
    staticClass: "p-50 py-sm-1 px-sm-2 text-nowrap",
    attrs: {
      "variant": "gradient",
      "pill": "",
      "disabled": !_vm.selectedTrip[_vm.tabIndex] && !_vm.combinationSelectedTrip
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm.tabIndexNotSelect < 0 || _vm.selectedTrip.length === 1 || _vm.combinationSelectedTrip ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.submit')) + " ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.nextFlight')) + " ")])])], 1)])]), _c('ModalWarningGoToReservation', {
    on: {
      "confirm": _vm.gotoReservation
    }
  }), _vm.tabIndexNotSelect === -1 ? _c('ModalInfoCreateGroupBooking', {
    attrs: {
      "selected-trip": _vm.draftSelectedTrip
    },
    on: {
      "goToReservation": _vm.handleClick
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }